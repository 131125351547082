<template>
  <section class="content-switch" v-class-mod:content-switch="{ removeSpacing }">
    <cta-button
      v-if="!isDisabled && !!switchBtn"
      v-bind="isVisible ? switchBtn.hide : switchBtn.show"
      :action="() => (isVisible = !isVisible)"
      class="content-switch__cta"
    />
    <div v-if="!isDisabled && !isVisible" class="content-switch__info-section">
      <svg-illustration v-if="illustrationName" :illustration="illustrationName" class="content-switch__illustration" />
      <icon-wrapper v-else-if="iconName" :icon="iconName" color="black-28" class="content-switch__icon" size="xlarge" />
      <h3 class="content-switch__title">{{ title }}</h3>
      <h3 v-if="explanation" class="content-switch__explanation">{{ explanation }}</h3>
    </div>
    <div v-show="isDisabled || isVisible" class="content-switch__content-slot">
      <slot />
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { IconName } from "@horizon56/fonts/types";

import CtaButton, { CtaButtonProps } from "@/components/buttons/cta-button.vue";
import IconWrapper from "@/components/icons/icon-wrapper.vue";
import SvgIllustration, { IllustrationName } from "@/components/icons/svg-illustration.vue";

type SwitchOptions = Pick<CtaButtonProps, "title" | "theme">;

defineProps<{
  title: string;
  explanation?: string;
  illustrationName?: IllustrationName;
  iconName?: IconName;
  isDisabled?: boolean;
  removeSpacing?: boolean;
  switchBtn?: {
    show: SwitchOptions;
    hide: SwitchOptions;
  };
}>();

const isVisible = ref(false);
</script>

<style lang="scss" scoped>
.content-switch {
  $block: &;
  display: flex;
  flex-flow: column;
  justify-content: center;
  &__info-section {
    display: flex;
    flex-flow: column;
    align-items: center;
    align-self: center;
    padding: var(--app-spacing-size-xlarge);
    row-gap: var(--app-spacing-size-xlarge);
    text-align: center;
    &:has(#{$block}__icon) {
      margin-top: var(--app-spacing-size-xlarge);
    }
  }
  &__cta {
    align-self: center;
    flex-shrink: 0;
    margin-top: var(--app-spacing-size-large);
    margin-bottom: var(--app-spacing-size-xlarge);
  }
  &__illustration {
    width: 180px;
    max-width: 100%;
  }
  &__explanation {
    color: var(--black-50);
  }
  &__content-slot {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  &--removeSpacing & {
    &__cta {
      margin-top: 0;
    }
    &__info-section {
      padding-bottom: 0;
    }
  }
}
</style>
